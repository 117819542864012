// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `


.home-background{
    background-color: #1d1e26;
    height: 100vh;
    color: #d6d6d6;
    margin-left: 75px;
}
.home-title-container{
   
}
.home-title{
    font-size: 5.2rem;
   
    
}
.home-title-name-container{
    
}
.home-container{
    display: flex;
    justify-content: space-between;
    margin-top: 11%;
    margin-left: 15%;
    margin-right: 15%;
    align-items: center;
}
.home-title:hover
{
    color: #62c990;
    transform: translate(-10px);
    
}
.home-title-top{
    font-size: 4.5rem;
    color: #62c990;
}
.home-subtitle{
    font-size: 2.1rem;
}
.home-intro{
    font-size: 1rem;
}
.home-picture-container{
    align-items: center;
    justify-content: center;
}
.home-img{
    height: 40vh;
}`, "",{"version":3,"sources":["webpack://./src/pages/Home/home.css"],"names":[],"mappings":";;;AAGA;IACI,yBAAyB;IACzB,aAAa;IACb,cAAc;IACd,iBAAiB;AACrB;AACA;;AAEA;AACA;IACI,iBAAiB;;;AAGrB;AACA;;AAEA;AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;AACvB;AACA;;IAEI,cAAc;IACd,2BAA2B;;AAE/B;AACA;IACI,iBAAiB;IACjB,cAAc;AAClB;AACA;IACI,iBAAiB;AACrB;AACA;IACI,eAAe;AACnB;AACA;IACI,mBAAmB;IACnB,uBAAuB;AAC3B;AACA;IACI,YAAY;AAChB","sourcesContent":["\n\n\n.home-background{\n    background-color: #1d1e26;\n    height: 100vh;\n    color: #d6d6d6;\n    margin-left: 75px;\n}\n.home-title-container{\n   \n}\n.home-title{\n    font-size: 5.2rem;\n   \n    \n}\n.home-title-name-container{\n    \n}\n.home-container{\n    display: flex;\n    justify-content: space-between;\n    margin-top: 11%;\n    margin-left: 15%;\n    margin-right: 15%;\n    align-items: center;\n}\n.home-title:hover\n{\n    color: #62c990;\n    transform: translate(-10px);\n    \n}\n.home-title-top{\n    font-size: 4.5rem;\n    color: #62c990;\n}\n.home-subtitle{\n    font-size: 2.1rem;\n}\n.home-intro{\n    font-size: 1rem;\n}\n.home-picture-container{\n    align-items: center;\n    justify-content: center;\n}\n.home-img{\n    height: 40vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
