import styled from 'styled-components'
import {NavLink as Link } from 'react-router-dom'

import * as AiIcons from 'react-icons/ai'


export const SidebarData = [
    {
        title: 'Home',
        path: '/',
        icon: <AiIcons.AiFillHome />,
        // iconClosed: <RiIcons.RiArrowDownSFill />, // THIS IS AN EXAMPLE OF A NESTED MENU
        // iconOpened: <RiIcons.RiArrowUpSFill />,
        // subNav: [
        //     {
        //         title: 'Home',
        //         path: '/home',
        //         icon: <AiIcons.AiFillHome />, 
        //     },
        //     {
        //         title: 'Home',
        //         path: '/home',
        //         icon: <AiIcons.AiFillHome />, 
        //     },
            
        // ]
       },
       {
        title: 'About',
        path: '/about',
        icon: <AiIcons.AiFillContacts />,

       },
       {
        title: 'Projects',
        path: '/projects',
        icon: <AiIcons.AiFillPicture />,

       },
       {
        title: 'Contact',
        path: '/contact',
        icon: <AiIcons.AiFillPhone />,

       },
]



export const Nav = styled.div`
    background: #15171c;
    height: 80px;
    justify-content: flex-start;
    align-items: center;
    
`;

export const NavIcon = styled(Link)`
    margin-left: 2rem;
    font-size: 2rem;
    height: 80px;
    display: flex;
    position: fixed;
    top: 0;
    left: 0px;
    justify-content: flex-start;
    align-items: center;
`;

export const SidebarNav = styled.nav`
    background: #15171c;
    width: ${({ sidebar }) => (sidebar ? '170px' : '75px')};
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0px;
    transition: 250ms;
    z-index: 10;

    

`;
export const SidebarWrap = styled.div`
    background: #15171c;
    width: 100%;

`;
export const SidebarLink = styled(Link)`
    display: flex;
    color: #e1e9fc;
    //justify-content: space-between;
    align-items: center;
    padding: 20px;
    list-style: none;
    height:80px;
    text-decoration: none;
    font-size: ${({ sidebar }) => (sidebar ? '20px' : '28px')};
    transition: all 350ms ease-in-out;

    &:hover {
        color: #62C990;
        background: #252831;
        border-left: 4px solid #62C98F;
        cursor: pointer;
        display: flex;
        
    }
`;

export const SidebarLabel = styled.span`
    margin-left:16px;
    font-size: 18px;
    
    

`;


// export const NavLink = styled(Link)`
//     color: #fff;
//     display: flex;
//     align-items: center;
//     text-decoration: none;
//     padding 0 1rem;
//     height: 100%
//     cursor: pointer;

//     &.active{
//         color: #15cdfc;
//     }
// `;

// export const Bars = styled(FaBars)`
//     display: none;
//     color: #fff;

//     @media screen and (max-width: 768px) {
//         display: block;
//         position: absolute;
//         top: 0;
//         right: 0;
//         transform: translate(-100%, 75%);
//         font-size: 1.8rem;
//         cursor: pointer;

//     }
// `;
// export const NavMenu = styled.div`
//     display: flex;
//     align-items: center;
//     margin-right: -24px;
    
//     @media screen and (max-width: 768px){
//         display: none;
//     }
// `;

// export const NavBtn = styled.nav`
//     display: flex;
//     align-items: center;
//     margin-right: 24px;
    
//     @media screen and (max-width: 768px){
//         display: none;
//     }
// `;
// export const NavBtnLink = styled(Link)`
//     border-radius: 4px;
//     background: #256ce1;
//     padding: 10px 22px;
//     color: #fff;
//     border: none;
//     cursor: pointer;
//     transition: all 0.2s ease-in-out;
//     text-decoration: none;

//     &:hover {
//         transition: all 0.2s ease-in-out; 
//         background: #fff;
//         color: #010606;

//     }
// `;