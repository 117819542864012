// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Sora&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{
  font-family: 'Sora', sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  
}
.App {
  width: 100vw;
  height: 100vh;
  background-color: #292D3F;
  background-color: #1d1e26;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;EACE,+BAA+B;EAC/B,sBAAsB;EACtB,SAAS;EACT,UAAU;;AAEZ;AACA;EACE,YAAY;EACZ,aAAa;EACb,yBAAyB;EACzB,yBAAyB;AAC3B","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Sora&display=swap');\n\n*{\n  font-family: 'Sora', sans-serif;\n  box-sizing: border-box;\n  margin: 0;\n  padding: 0;\n  \n}\n.App {\n  width: 100vw;\n  height: 100vh;\n  background-color: #292D3F;\n  background-color: #1d1e26;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
