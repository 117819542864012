// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-background{
    background-color: #1d1e26;
    height: 100%;
    color: #d6d6d6;
    margin-left: 75px;
   
}
.about-title-container{
    width: 55%;
}
.about-title{
    font-size: 5.2rem;
}
.about-subtitle{
    font-size: 2.1rem;
}
.about-intro{
    font-size: 1rem;
    flex-wrap: wrap;
   
}
.about-language-container{
    width: 50%;
   display: flex;
   flex-wrap: wrap;
   justify-content: space-evenly;
   align-items: space-evenly;
   ;
}
.about-img{
   
    height: 40vh;
}
.about-container {
    display: flex;
    justify-content: space-between;
    margin-top: 16%;
    margin-left: 15%;
    margin-right: 15%;
}
.language-img{
    height: 150px;
    width: 150px;
}
.about-language{
    margin: 10px;
    position: relative;
    overflow: hidden;
}
.coming-soon{
    background-color: red;
    color: white;
    transform: rotate(45deg);
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 25px;
    font-size: 12px;
    transform-origin: 35% 350%;
}`, "",{"version":3,"sources":["webpack://./src/pages/About/about.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;IACZ,cAAc;IACd,iBAAiB;;AAErB;AACA;IACI,UAAU;AACd;AACA;IACI,iBAAiB;AACrB;AACA;IACI,iBAAiB;AACrB;AACA;IACI,eAAe;IACf,eAAe;;AAEnB;AACA;IACI,UAAU;GACX,aAAa;GACb,eAAe;GACf,6BAA6B;GAC7B,yBAAyB;;AAE5B;AACA;;IAEI,YAAY;AAChB;AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB;AACA;IACI,aAAa;IACb,YAAY;AAChB;AACA;IACI,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;AACpB;AACA;IACI,qBAAqB;IACrB,YAAY;IACZ,wBAAwB;IACxB,kBAAkB;IAClB,MAAM;IACN,QAAQ;IACR,eAAe;IACf,eAAe;IACf,0BAA0B;AAC9B","sourcesContent":[".about-background{\n    background-color: #1d1e26;\n    height: 100%;\n    color: #d6d6d6;\n    margin-left: 75px;\n   \n}\n.about-title-container{\n    width: 55%;\n}\n.about-title{\n    font-size: 5.2rem;\n}\n.about-subtitle{\n    font-size: 2.1rem;\n}\n.about-intro{\n    font-size: 1rem;\n    flex-wrap: wrap;\n   \n}\n.about-language-container{\n    width: 50%;\n   display: flex;\n   flex-wrap: wrap;\n   justify-content: space-evenly;\n   align-items: space-evenly;\n   ;\n}\n.about-img{\n   \n    height: 40vh;\n}\n.about-container {\n    display: flex;\n    justify-content: space-between;\n    margin-top: 16%;\n    margin-left: 15%;\n    margin-right: 15%;\n}\n.language-img{\n    height: 150px;\n    width: 150px;\n}\n.about-language{\n    margin: 10px;\n    position: relative;\n    overflow: hidden;\n}\n.coming-soon{\n    background-color: red;\n    color: white;\n    transform: rotate(45deg);\n    position: absolute;\n    top: 0;\n    right: 0;\n    padding: 0 25px;\n    font-size: 12px;\n    transform-origin: 35% 350%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
