import React from 'react'
import './about.css';

import js from '../../Photos/javascript.png';
import ts from '../../Photos/typescript.svg';
import react from '../../Photos/React.webp';
import RN from '../../Photos/ReactNative.webp';
import node from '../../Photos/node.png';
import wp from '../../Photos/media.svg';
import ruby from '../../Photos/rails.png';
import cpp from '../../Photos/c++.svg';
import sql from '../../Photos/sql.jpg';
import jq from '../../Photos/jquery.webp';
import api from '../../Photos/api.png';
import py from '../../Photos/python.jpg';
import java from '../../Photos/java.png';


const languages = [
    { name: 'Javascript', img: js, commingSoon: false },
    { name: 'Typescript', img: ts, commingSoon: true },
    { name: 'React.js', img: react, commingSoon: false },
    { name: 'React-Native', img: RN, commingSoon: false },
    { name: 'Node.js', img: node, commingSoon: false },
    { name: 'Wordpress', img: wp, commingSoon: false },
    { name: 'Ruby', img: ruby, commingSoon: false },
    { name: 'C++', img: cpp, commingSoon: true },
    { name: 'SQL', img: sql, commingSoon: false },
    { name: 'jQuery', img: jq, commingSoon: false },
    { name: 'API', img: api, commingSoon: false },
    { name: 'Python', img: py, commingSoon: false },
    { name: 'Java', img: java, commingSoon: true },
]

const About = () => {
    return (
        <div className="about-background">
            <div className='about-container'>
                <div className="about-title-container">
                    <h1 className="about-title">About Me</h1>
                    <h3 className="about-subtitle">Front-End and Full-Stack Development</h3>
                    <br />
                    <p className="about-intro">Hello, I am a front-end developer specializing in the React.js framework. I graduated from DevMountain Coding Bootcamp, and am always expanding my knowledge base.
                        I have proficient skills in full-stack development, primarily working in the React.js framework and Node.js backend. Though I also am familliar with other frameworks such as React Native and Wordpress Development. I am wanting to move into a more backend role, and am currently learning Ruby, C++ and Express.js.
                        <br />
                        <br />
                    </p>
                    <p className="about-intro">
                        I love new and challenging projects on both large and small scales. I am currently working as lead developer for Thrice.Agency, leading a team to work on a veriety of projects including a React-Native app, over 20 wordpress sites, and denovo React.js websites. </p>
                </div>
                <div className="about-language-container">
                    {languages.map((language, index) => {
                        return (
                            <div className="about-language" key={index}>
                                {language.commingSoon && <div className="coming-soon">In-Progress</div>}
                                <img src={language.img} alt={language.name} className="language-img" />
                                {/* <p className="about-img-text">{language.name}</p> */}
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default About


