import React from 'react';
import './App.css';
import SideNav from './components/Nav/Sidebar'  ;
import {BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home/Home';
import About from './pages/About/About';
import Projects from './pages/Projects/Projects';
import Services from './pages/Services/Services';
import Contact from './pages/Contact/Contact';

function App(props, state) {
  return (
    
    <Router>
      <SideNav/>
      <Routes>
        <Route exact path='/' element={<Home/>} />
        <Route exact path='/about' element={<About/>} />
        <Route exact path='/services' element={<Services/>} />
        <Route exact path='/projects' element={<Projects/>} />
        <Route exact path='/contact' element={<Contact/>} />
      </Routes> 
    </Router>
    
        
    
  );
}

export default App;
