import React from 'react'
import './contact.css';

const Contact = () => {
    return (
        <div>
            <div className="contact-background">
                <div className='outer-contact-container'>
                <div className="contact-title-container">
                    <h1 className="contact-title">Contact</h1>
                    
                </div>
                <div>
                    <h3 className="contact-email contact-subtitle">Email: <a href="mailto:elikeetch@gmail.com">elikeetch@gmail.com</a></h3>
                    <h3 className="contact-phone contact-subtitle">Phone: <a href="tel:9196096439">919.609.6439</a></h3>
                    <h3 className="contact-git contact-subtitle">GitHub: <a href="https://github.com/eliKeetch">elikeetch</a></h3>

                    
                </div>
                </div>

            </div>
        </div>
    )
}

export default Contact
