import React from 'react';
import './home.css';
import placeholder from '../../Photos/placeholder.png'
import profile from '../../Photos/IMG_4372.JPEG'

const Home = () => {

const checkSite = async (url) => {
    
    fetch(url, {
      method: "GET",
      headers: {
        accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      }
    }).then((response) => {
      
      console.log( response, "JFJFJFJF");
      if(response.ok){
        console.log('Site is up');
      }else{
        console.log('Site is down');
      }
    }).catch((error) => {
      console.log(error);
    });
   
  }

    return (
        <div className="home-background">
            <div className='home-container'>
            <div className="home-title-container">
            <h1 className="home-title-top">Hello I am, </h1>
            <h1 className="home-title-name-container">
            <span className="home-title">E</span>
            <span className="home-title">l</span>
            <span className="home-title">i</span>
            <span className="home-title"> </span>
            <span className="home-title">K</span>
            <span className="home-title">e</span>
            <span className="home-title">e</span>
            <span className="home-title">t</span>
            <span className="home-title">c</span>
            <span className="home-title">h</span>
            </h1>
		<button onClick={() => checkSite('https://thrice.agency')}>Ping</button>
            <h3 className="home-subtitle">Front-End and Full-Stack Development</h3>

            
            </div>
            <div className="home-picture-container">
                <img src={profile} alt={'placeholder'} className="home-img"/>
                {/* <p> logo? or other things maybe a picture</p> */}
            </div>
            </div>
        </div>
    )
}

export default Home
