import React, {useState} from 'react'
import { SidebarNav, SidebarWrap, NavIcon, SidebarLink, SidebarLabel } from './NavBarElements'

import { SidebarData } from './NavBarElements';


const SideNav = () => {
    const [sidebar, setSidebar] = useState(false);
    
    const showSidebar = () => setSidebar(!sidebar)


   
    return (
        <div  >
            
                {/* <Nav>
                <NavIcon to='#'>
                    <FaIcons.FaBars onClick={showSidebar}/>
                </NavIcon>
                </Nav> */}
                
                     
                <SidebarNav onMouseEnter={showSidebar} onMouseLeave={showSidebar} sidebar={sidebar}>
                    <SidebarWrap>
                        <NavIcon to='#'>
                             {/* put logo icon here */}
                        </NavIcon>
                        {SidebarData.map((item, index) =>{
                            return <SidebarLink to={item.path} sidebar={sidebar}>
                
                            <span> 
                                {item.icon}    
                            </span>
                            <span> 
                                {sidebar ? <SidebarLabel>{item.title}</SidebarLabel> : ''}
                            </span>
                        </SidebarLink>
                        })}
                    </SidebarWrap>
                </SidebarNav>
                

            
        </div>
    );
};

export default SideNav;
