import React from 'react';
import './services.css';
import IOM from '../../Photos/IOMScreenshot.png';
import JLH from '../../Photos/JustLikeHomeScreenshot.png';
import NME from '../../Photos/NMEScreenshot.png';

const Services = () => {
    return (

        <div className="services-background">
            <div className="services-title-container">
                <h1 className="services-title">Services</h1>
                <h2 className="services-subtitle">-Work Examples</h2>
            </div>
            <div className='services-container'>
                <div className="services-option-container">
                    {/* <img src={placeholder} className="home-img"/> */}
                    <h1>Website Development</h1>
                    <h3>New Buisness Development</h3>
                    <p>Developing a fully custom from scratch website for your buisness to your specifications</p>
                    <h3>Website Overhaul</h3>
                    <p>Re-Development of an existing website</p>
                    <h3>Porfolios and Personal Websites</h3>
                    <p>Personal website for job searches or your buisness, aswell as portfolios to show off your works</p>
                    <h3>Single Page Website</h3>
                    <p>Great starting point for new buisnesses getting your name out there</p>


                </div>
                <div className="services-option-container">
                    <h1>App Development</h1>
                    <h3>React-Native App Development</h3>
                    <p>A fully customized mobile app for your buisness, made with your specifications and buisness needs</p>
                    <h3>Sharetribe marketplace setup</h3>
                    <p>Setup and initialization of sharetribe marketplace</p>



                </div>

            </div>

            <div className='experience-container'>
                <div className='experience-box'>
                    <img className='screenshot' href="https://justlikehomestaging.com/" src={JLH}/>
                    <h1 className='experience-title'>Wordpress or Site Builder Setup</h1>
                    <h4 className='link-hover' href="https://justlikehomestaging.com/">Just Like Home Staging</h4>
                    <p className='experience-content'>This is an example of a website built with wordpress or other site builder tools, I can add customizations, updates or do complete overhauls to update your new or existing website to a new look.</p>
                </div>
                <div className='experience-box'>
                    <img className='screenshot' href="http://nicholesmicroestates.com/" src={NME}/>
                    <h1 className='experience-title'>Custom Single Page Website</h1>
                    <h4 className='link-hover' href="http://nicholesmicroestates.com/">Nicholes Micro Estates</h4>
                    <p className='experience-content'>This is an example of a single page website for small buisnesses just starting out. Built in React.js this site is built from the ground up with every aspect customized to your needs.</p>
                </div>
                <div className='experience-box'>
                    <img className='screenshot'  href="https://iom.ai/" src={IOM}/>
                    <h1 className='experience-title'>Fully Custom Website</h1>
                    <h4 className='link-hover' href="https://iom.ai/">Invest On Main</h4>
                    <p className='experience-content'>This is an example of a large scale project. I lead a team to build a custom solution for a real-estate investment firm. It is built in React.js utilizing a custom Node.js midddle-man API to ensure site security and reliability.</p>
                </div>


            </div>
        </div>

    )
}

export default Services
