// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-background{
    background-color: #1d1e26;
    height: 100vh;
    color: #d6d6d6;
    margin-left: 85px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

}
.outer-contact-container{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 50%;

}
.contact-title-container{
   
}
.contact-title{
    font-size: 5.2rem;
   
    
}
.contact-title-name-container{
    
}
.contact-container{
    display: flex;
    justify-content: space-between;
    margin-top: 3%;
    margin-left: 5%;
    margin-right: 5%;
    align-items: center;
}
.contact-title:hover
{
    color: #62c990;
   
    
}
.contact-title-top{
    font-size: 4.5rem;
    color: #62c990;
}
.contact-subtitle{
    font-size: 2.1rem;
}
.contact-intro{
    font-size: 1rem;
}
.contact-email a, .contact-phone a, .contact-git a{
    color: #62c990;
    text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/pages/Contact/contact.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,aAAa;IACb,cAAc;IACd,iBAAiB;IACjB,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;;AAEvB;AACA;IACI,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;IACnB,UAAU;;AAEd;AACA;;AAEA;AACA;IACI,iBAAiB;;;AAGrB;AACA;;AAEA;AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,mBAAmB;AACvB;AACA;;IAEI,cAAc;;;AAGlB;AACA;IACI,iBAAiB;IACjB,cAAc;AAClB;AACA;IACI,iBAAiB;AACrB;AACA;IACI,eAAe;AACnB;AACA;IACI,cAAc;IACd,qBAAqB;AACzB","sourcesContent":[".contact-background{\n    background-color: #1d1e26;\n    height: 100vh;\n    color: #d6d6d6;\n    margin-left: 85px;\n    display: flex;\n    justify-content: space-evenly;\n    align-items: center;\n\n}\n.outer-contact-container{\n    display: flex;\n    justify-content: space-evenly;\n    align-items: center;\n    width: 50%;\n\n}\n.contact-title-container{\n   \n}\n.contact-title{\n    font-size: 5.2rem;\n   \n    \n}\n.contact-title-name-container{\n    \n}\n.contact-container{\n    display: flex;\n    justify-content: space-between;\n    margin-top: 3%;\n    margin-left: 5%;\n    margin-right: 5%;\n    align-items: center;\n}\n.contact-title:hover\n{\n    color: #62c990;\n   \n    \n}\n.contact-title-top{\n    font-size: 4.5rem;\n    color: #62c990;\n}\n.contact-subtitle{\n    font-size: 2.1rem;\n}\n.contact-intro{\n    font-size: 1rem;\n}\n.contact-email a, .contact-phone a, .contact-git a{\n    color: #62c990;\n    text-decoration: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
