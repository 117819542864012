import React from 'react'
import './projects.css';
import IOM from '../../Photos/IOMScreenshot.png';
import AT from '../../Photos/AtlasScreen.png';
import CB from '../../Photos/CommunityScreenshot.png';
import CT from '../../Photos/CuldianTrustScreenshot.png';
import JLH from '../../Photos/JustLikeHomeScreenshot.png';
import NME from '../../Photos/NMEScreenshot.png';
import TSOL from '../../Photos/TSOLScreenshot.png';
import LC from '../../Photos/LibertyClassroomScreenshot.png';
import LG from '../../Photos/linkedguerilla.png';
import BAL from '../../Photos/ballet.png';
import FLY from '../../Photos/flyerposting.png';
import MAC from '../../Photos/macon.png';
import SAR from '../../Photos/sarcina.png';
import VIN from '../../Photos/thruthevines.png';
import THR from '../../Photos/thrice.png';
import MH from '../../Photos/mckerrow.png';

const Projects = () => {

    const screenShots = [
        {
            image: IOM,
            link: "https://iom.ai/",
            name: "Invest On Main"
        }, {
            image: AT,
            link: "https://atlastalked.com/",
            name: "Atlas Talked"
        }, {
            image: CB,
            link: "https://communitybootstrap.com/",
            name: "Community Bootstrap"
        }, {
            image: CT,
            link: "https://culdiantrust.org/",
            name: "Culdian Trust"
        }, {
            image: JLH,
            link: "https://justlikehomestaging.com/",
            name: "Just Like Home Staging"
        },  {
            image: TSOL,
            link: "https://tomschooloflife.com",
            name: "Tom School of Life"
        }, {
            image: LC,
            link: "https://libertyclassroom.com/",
            name: "Liberty Classroom"
        },{
            image: LG,
            link: "https://linkedguerilla.com/",
            name: "Linked Guerilla"
        },{
            image: BAL,
            link: "https://balletatelier.com/",
            name: "Ballet Atelier"
        },{
            image: FLY,
            link: "https://flyerposting.com/",
            name: "Flyer Posting"
        },{
            image: MAC,
            link: "https://macontruckpark.com/",
            name: "Macon Truck Park"
        },{
            image: SAR,
            link: "https://sarcina.betterbetterbetter.website/",
            name: "Sarcina"
        },{
            image: VIN,
            link: "https://thruthevines.com/",
            name: "Through The Vines"
        },{
            image: THR,
            link: "https://thrice.agency/",
            name: "Thrice Agency"
        },{
            image: MH,
            link: "https://mckerrowhistory.com/",
            name: "McKerrow History"
        }
    
    
    ];


    console.log(screenShots.IOM)

    return (
        <div>
            <div className="projects-background">
                <div className='projects-container'>
                    <div className="projects-title-container">
                        <h1 className="projects-title">Projects</h1>
                        <h3 className="projects-subtitle">React.js, React Native and Wordpress Websites</h3>

                        {/* <p className="projects-intro">Hello, I am a front-end developer specializing in the React.js framework.</p> */}
                    </div>
                    
                </div>
                <div>
                    <div className='screenshot-container'>
                    {screenShots.sort(() => Math.random() - 0.5).map((screenshot) => {
                        return (
                            <div className='projects-box'>
                                {console.log(screenshot.link)}
                                <div className='overlay'>
                                    <div className='project-name'>{screenshot.name}</div>
                                </div>
                                <a className='project-link' href={screenshot.link} target="_blank">
                                
                                
                                </a>
                                <img className='screenshot' src={screenshot.image} />
                                
                            </div>
                        )
                    })}
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Projects
